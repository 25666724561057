import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MessageService } from 'primeng/api';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { CompanyType, Ctx } from 'src/app/shared/api-structures/common';
import { LanguageService } from 'src/app/shared/services/language.service';
import { CompaniesService } from 'src/app/super-admin/services/companies.service';
import _ from 'underscore';
import { ConfirmDialogComponent } from '../../../../../shared/components/confirm-dialog/confirm-dialog.component';

export enum ComponentMode {
  CREATE,
  UPDATE
}

@Component({
  selector: 'create-update-company',
  templateUrl: './create-update-company.component.html',
  providers: [MessageService],
})
export class CreateUpdateCompanyComponent {
  id: string
  organizationId: string
  ctx = ''
  environmentId: string = ''
  isEnvDisabled = true
  environmentsOptions: string[] = []
  // region: string
  firestoreRegion: string = ""
  firestoreRegionOptions = [
    ["us-west1", "Oregon"],
    ["us-west2", "Los Angeles"],
    ["us-west3", "Salt Lake City"],
    ["us-west4", "Las Vegas"],
    ["northamerica-northeast1", "Montréal"],
    ["northamerica-northeast2", "Toronto"],
    ["us-east1", "South Carolina"],
    ["us-east4", "Northern Virginia"],
    ["southamerica-east1", "São Paulo"],
    ["europe-west2", "London"],
    ["europe-west1", "Belgium"],
    ["europe-west4", "Netherlands"],
    ["europe-west9", "Paris"],
    ["europe-west3", "Frankfurt"],
    ["europe-central2", "Warsaw"],
    ["europe-west6", "Zürich"],
    ["me-central1", "Doha"],
    ["me-central2", "Dammam"],
    ["me-west1", "Tel Aviv"],
    ["asia-southeast1", "Singapore"],
    ["asia-southeast2", "Jakarta"],
    ["asia-east2", "Hong Kong"],
    ["asia-east1", "Taiwan"],
    ["asia-northeast1", "Tokyo"],
    ["asia-northeast2", "Osaka"],
    ["asia-northeast3", "Seoul"],
    ["australia-southeast1", "Sydney"]
  ]
  bucketRegion: string = ""
  bucketRegionOptions = [
    ["NORTHAMERICA-NORTHEAST1", "Montréal"],
    ["NORTHAMERICA-NORTHEAST2", "Toronto"],
    ["US-CENTRAL1", "Iowa"],
    ["US-EAST1", "South Carolina"],
    ["US-EAST4", "Northern Virginia"],
    ["US-EAST5", "Columbus"],
    ["US-SOUTH1", "Dallas"],
    ["US-WEST1", "Oregon"],
    ["US-WEST2", "Los Angeles"],
    ["US-WEST3", "Salt Lake City"],
    ["US-WEST4", "Las Vegas"],
    ["SOUTHAMERICA-EAST1", "São Paulo"],
    ["SOUTHAMERICA-WEST1", "Santiago"],
    ["EUROPE-CENTRAL2", "Warsaw"],
    ["EUROPE-NORTH1", "Finland"],
    ["EUROPE-SOUTHWEST1", "Madrid"],
    ["EUROPE-WEST1", "Belgium"],
    ["EUROPE-WEST2", "London"],
    ["EUROPE-WEST3", "Frankfurt"],
    ["EUROPE-WEST4", "Netherlands"],
    ["EUROPE-WEST6", "Zürich"],
    ["EUROPE-WEST8", "Milan"],
    ["EUROPE-WEST9", "Paris"],
    ["EUROPE-WEST10", "Berlin"],
    ["EUROPE-WEST12", "Turin"],
    ["ASIA-EAST1", "Taiwan"],
    ["ASIA-EAST2", "Hong Kong"],
    ["ASIA-NORTHEAST1", "Tokyo"],
    ["ASIA-NORTHEAST2", "Osaka"],
    ["ASIA-NORTHEAST3", "Seoul"],
    ["ASIA-SOUTHEAST1", "Singapore"],
    ["ASIA-SOUTH1", "Mumbai"],
    ["ASIA-SOUTH2", "Delhi"],
    ["ASIA-SOUTHEAST2", "Jakarta"],
    ["ME-CENTRAL1", "Doha"],
    ["ME-CENTRAL2", "Dammam, Saudi Arabia"],
    ["ME-WEST1", "Tel Aviv"],
    ["AUSTRALIA-SOUTHEAST1", "Sydney"],
    ["AUSTRALIA-SOUTHEAST2", "Melbourn"]
  ]
  bigqueryRegion: string = ""
  bigqueryRegions = [{
    label: 'Multi-regions',
    regions: [
      ["EU", "EU"],
      ["US", "US"],
    ]
  }, {
    label: 'Americas',
    regions: [
      ["Columbus, Ohio", "us-east5"],
      ["Dallas", "us-south1"],
      ["Iowa", "us-central1", true],
      ["Las Vegas", "us-west4"],
      ["Los Angeles", "us-west2"],
      ["Montréal", "northamerica-northeast1", true],
      ["Northern Virginia", "us-east4"],
      ["Oregon", "us-west1", true],
      ["Salt Lake City", "us-west3"],
      ["São Paulo", "southamerica-east1", true],
      ["Santiago", "southamerica-west1", true],
      ["South Carolina", "us-east1"],
      ["Toronto", "northamerica-northeast2", true],
    ]
  }, {
    label: 'Asia Pacific',
    regions: [
      ["Delhi", "asia-south2"],
      ["Hong Kong", "asia-east2"],
      ["Jakarta", "asia-southeast2"],
      ["Melbourne", "australia-southeast2"],
      ["Mumbai", "asia-south1"],
      ["Osaka", "asia-northeast2"],
      ["Seoul", "asia-northeast3"],
      ["Singapore", "asia-southeast1"],
      ["Sydney", "australia-southeast1"],
      ["Taiwan", "asia-east1"],
      ["Tokyo", "asia-northeast1"],
    ]
  }, {
    label: 'Europe',
    regions: [
      ["Belgium", "europe-west1", true],
      ["Berlin", "europe-west10"],
      ["Finland", "europe-north1", true],
      ["Frankfurt", "europe-west3", true],
      ["London", "europe-west2", true],
      ["Madrid", "europe-southwest1"],
      ["Milan", "europe-west8"],
      ["Netherlands", "europe-west4"],
      ["Paris", "europe-west9", true],
      ["Turin", "europe-west12"],
      ["Warsaw", "europe-central2"],
      ["Zürich", "europe-west6", true],
    ]
  }, {
    label: 'Middle East',
    regions: [
      ["Dammam", "me-central2"],
      ["Doha", "me-central1"],
      ["Tel Aviv", "me-west1"],
    ]
  }, {
    label: 'Africa',
    regions: [
      ["Johannesburg", "africa-south1"],
    ]
  }]
  bigqueryRegionsfiltered = this.bigqueryRegions

  // ReginOptions: string[] = ['Not implemented']
  createUpdateMode: "CREATE" | "UPDATE"
  createUpdatetitle: string = 'CreateCompany'
  confirmTitle: string = 'ConfirmCreationTitle'
  confirmMessage: string = 'confirmComanyCreationMessage'
  isExpandMore: boolean = false
  companyType: CompanyType
  CompanyTypes: CompanyType[] = ['Standalone', 'MC1']
  resourceName: string
  wtmKey?: string

  constructor(public dialogRef: MatDialogRef<CreateUpdateCompanyComponent>,
    private languageService: LanguageService, @Inject(MAT_DIALOG_DATA) public data: any,
    private snackbarService: SnackbarService, private dialog: MatDialog,
    private companiesService: CompaniesService, private messageService: MessageService) {
    if (data.name !== undefined) {
      this.createUpdateMode = "UPDATE"
      this.companyType = data.companyType
      this.id = data.id
      this.environmentId = data.environmentId
      this.createUpdatetitle = 'UpdateCompany'
      this.confirmTitle = 'ConfirmUpdateTitle'
      this.confirmMessage = 'ConfirmCompanyUpdateMessage'
    } else {
      this.createUpdateMode = "CREATE"
    }
    this.organizationId = data.organizationId.trim()
  }

  openConfirmDialog() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25vw',
      data: {
        title: `${this.languageService.translateSync(this.confirmTitle)}`,
        message: `${this.languageService.translateSync(this.confirmMessage)} '${this.organizationId}/${this.ctx}'?`
      }
    })

    dialogRef.afterClosed().subscribe(async confirmResult => {
      if (!confirmResult) {
        return
      }
      this.save()
    })
  }

  filterBigqueryRegions() {
    if (!this.bigqueryRegion) {
      this.bigqueryRegionsfiltered = this.bigqueryRegions;
    } else {
      this.bigqueryRegionsfiltered = this.bigqueryRegions
        .map(v => ({
          label: v.label,
          regions: v.regions.filter(x =>
            (x[0] as string).toLowerCase().includes(this.bigqueryRegion.toLowerCase()) ||
            (x[1] as string).toLowerCase().includes(this.bigqueryRegion.toLowerCase()))
        }))
        .filter(v => v.regions.length > 0)
      if (this.bigqueryRegionsfiltered.length === 1 && this.bigqueryRegionsfiltered[0].regions.length === 1 && this.bigqueryRegionsfiltered[0].regions[0][1] === this.bigqueryRegion) {
        this.bigqueryRegionsfiltered = this.bigqueryRegions;
      }
    }
  }

  async save() {
    const ctx = `${this.organizationId}/${this.ctx}`

    if (this.createUpdateMode === "CREATE") {
      const o: Parameters<typeof this.companiesService.createCompany>[0] = {
        companyType: this.companyType,
        ctx: ctx as Ctx,
        resourceName: this.resourceName,
        firestoreRegion: this.firestoreRegion,
        bucketRegion: this.bucketRegion,
        bigqueryRegion: this.bigqueryRegion,
        env: this.environmentId,
      }

      if (this.companyType === 'MC1') {
        o.wtmKey = _.isEmpty(this.wtmKey) ? undefined : this.wtmKey
      }

      const res = await this.companiesService.createCompany(o)

      if (res?.isResourceExists) {
        this.firestoreRegion = res.firestoreRegion
        this.bucketRegion = res.bucketRegion
        this.bigqueryRegion = res.bigqueryRegion
        this.messageService.add({
          key: 'resourceExists',
          severity: 'error',
          summary: 'Resource already exists',
          closable: true,
          life: 60_000,
        })
        this.messageService.add({
          key: 'resourceExists',
          severity: 'error',
          summary: 'Be aware! All regions updated as existing',
          closable: true,
          life: 60_000,
        })
      } else {
        this.snackbarService.openSnackBar(2000, `${this.languageService.translateSync('companySuccessfullyCreated')}`)
        this.dialogRef.close({ isOrganizationUpdateOrCreated: true })
      }
    } else if (this.createUpdateMode === "UPDATE") {
      await this.companiesService.updateCompany({ organization: this.organizationId, environment: this.environmentId, company: this.id }, { companyType: this.companyType })
      this.snackbarService.openSnackBar(2000, `${this.languageService.translateSync('companySuccessfullyUpdated')}`)
      this.dialogRef.close({ isOrganizationUpdateOrCreated: true })
    }
  }

  calc() {
    const { env, resourceName } = this.calcResAndEnv(`${this.organizationId}/${this.ctx}`)

    this.environmentId = env
    this.resourceName = resourceName
  }


  calcResAndEnv(ctx: string) {
    ctx = `${ctx.toLowerCase()}//` // add extra / - to not trigger error

    const splitted = ctx.split('/')
    const p1 = splitted[0]
    const p2 = splitted[1]
    const p3 = splitted[2]

    const env = this.replaceAll(p2, ['.', ' '], '-')

    let resourceName = `${p1}--${p3}`
    resourceName = this.replaceAll(resourceName, ['.', ' '], '-')

    return { resourceName, env }
  }

  replaceAll(str: string, searchValue: string | string[], replaceValue: string): string {
    let result = str

    if (_.isArray(searchValue)) {
      for (const item of searchValue) {
        result = this.replaceAll(result, item, replaceValue)
      }
    } else {
      while (result.includes(searchValue)) {
        result = result.replace(searchValue, replaceValue)
      }
    }
    return result
  }
}