import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ListCustomersRequest, ListCustomersResponse } from 'src/app/shared/api-structures/admin/customer';
import { AutoDestroy } from 'src/app/shared/base-directives/auto-destroy';
import { CustomerService } from '../../services/customer.service';
import { MatDialog } from '@angular/material/dialog';
import { CustomFieldSettingsService } from '../../services/customFieldSettings.service';
import { CustomFieldSettingsWithId } from 'src/app/shared/api-structures/admin/customFieldSettings';
import { AddCustomerRequest } from 'src/app/shared/api-structures/admin/customer';
import { LatLng } from 'src/app/shared/api-structures/common';
import { GoogleMapsService } from 'src/app/shared/services/google-maps.service';
import { Observable } from 'rxjs';
import { LanguageService } from 'src/app/shared/services/language.service';
import { MatTableDataSource } from '@angular/material/table';
import { SortDirection } from '@angular/material/sort';
import { saveAs } from "file-saver";
import * as Papa from 'papaparse';
import { Router } from '@angular/router';
import { CustomerAssociatedUsersDialogComponent } from 'src/app/shared/components/associations/associated-users2/associated-users.component';
import { FilterDialogComponent } from './filter-dialog/filter-dialog.component';
import _ from 'underscore';
import { mockCsvData } from './csvTemplateData'

@Component({
  selector: 'customer-management',
  templateUrl: './customer-management.component.html',
  styleUrls: ['./customer-management.component.scss']
})

export class CustomerManagementComponent extends AutoDestroy implements OnInit {
  displayedColumns = ['id', 'name', 'address', 'customerCode', 'territory', 'category', 'chain', 'area', 'lastUpdate', 'externalId', 'users', 'edit', 'delete']
  data = []
  dataSource: MatTableDataSource<Record<string, string>>
  currentPage = 0
  pageSize = 25
  query = ''
  sortBy = 'id'
  sortDirection: SortDirection = 'asc'
  totalItens
  listUsersRequest: ListCustomersRequest
  listCustomerResponse: ListCustomersResponse
  listCustomerResponseForCsv: ListCustomersResponse
  customFields: CustomFieldSettingsWithId[]
  //customFields: CustomFieldConfig[]
  csvContent: string
  csvHeader: string[] = [];
  isShowConfirmDeleteDialog = false
  modalFilters = {
    dateFrom: null,
    dateTo: null,
  }

  importDialogData: any = {
    template: mockCsvData,
    title: "Customers",
    id: "Customers",
  };

  externalId: string
  name: string
  category: string
  address: string
  territory: string
  chain: string
  latLng: LatLng
  taxId: string
  customerCode: string
  lsMessages: string[] = []
  isShowModal = false

  autocompleteInput: string;
  queryWait: boolean;
  apiLoaded: Observable<boolean>;
  geocoder: google.maps.Geocoder;
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  mockDataCustomFields: string[][]

  constructor(public dialog: MatDialog,
    private customerService: CustomerService,
    private customFieldSettingsService: CustomFieldSettingsService,
    private googleMapsService: GoogleMapsService,
    private languageService: LanguageService,
    private router: Router) {
    super()
    this.apiLoaded = this.googleMapsService.loadApi()
    this.listUsersRequest = { currentPage: this.currentPage, query: '', pageSize: this.pageSize }
  }

  ngOnInit() {
    this.fetchCustomFields().then(customFields => {
      this.mockDataCustomFields = this.updateMockCsvDataWithCustomFields(customFields);
      this.importDialogData.template = this.mockDataCustomFields
    });

    this.apiLoaded.subscribe((res) => {
      if (res)
        console.log('google maps worked')
      else
        console.log('google maps failed')
    })

    this.refresh()
    this.customerService.listCustomers$.subscribe(d => {
      this.listCustomerResponseForCsv = d
      d.items.map(i => {
        i.address = i.addresses?.[0] ?? i.address
        i.territory = i.territories?.[0] ?? i.territory
      })
      this.data = d.items
      this.totalItens = d.totalItems
    })
  }

  scrollToBottom() {
    try {
      const element = document.querySelector('.p-dialog-content');
      if (element) {
        element.scrollTop = element.scrollHeight;

      }
    } catch (err) {
      console.error('Error scrolling to bottom:', err);
    }
  }

  updateMockCsvDataWithCustomFields(customFields: any[]): string[][] {
    const headers = mockCsvData[0];
    const rows = mockCsvData.slice(1);
    const relevantCustomFields = customFields.filter(field => field.fieldType !== 'select');
    const newHeaders = relevantCustomFields.map(field => field.name);
    headers.push(...newHeaders);
    const newRows = rows.map((row, rowIndex) => {
      const newRow = [...row];
      relevantCustomFields.forEach((field) => {
        let value: any;
        switch (field.fieldType) {
          case 'number':
            value = field.defaultValue !== undefined && field.defaultValue !== "" ? field.defaultValue : rowIndex + 1;
            break;

          case 'string':
            value = field.defaultValue !== undefined && field.defaultValue !== "" ? field.defaultValue : `defaultString_${rowIndex + 1}`;
            break;

          case 'boolean':
            value = field.defaultValue !== undefined && field.defaultValue !== "" ? field.defaultValue : (rowIndex % 2 === 0);
            break;

          case 'date':
            value = field.defaultValue !== undefined && field.defaultValue !== "" ? field.defaultValue : new Date().toISOString();
            break;

          default:
            value = 'default';
            break;
        }
        newRow.push(JSON.stringify(value));
      });

      return newRow;
    });
    return [headers, ...newRows];
  }

  onUsers(customer) {
    const dialogRef = this.dialog.open(CustomerAssociatedUsersDialogComponent, {
      panelClass: 'no-padding-dialog-container',
      width: '80vw',
      maxHeight: '95vh',
      data: { id: customer.id, name: customer.name, associationType: 'customer' }
    })
  }

  onEdit(customer) {
    this.router.navigate(['/admin/manage-customer'], { queryParams: { customer_id: customer.id } })
  }

  async onDelete(event) {
    await this.customerService.deleteCustomer({ id: event.id })
    this.refresh()
  }

  onPaginate({ pageIndex, pageSize }) {
    this.currentPage = pageIndex
    this.pageSize = pageSize
    this.customerService.loadCustomers({ currentPage: this.currentPage, pageSize: this.pageSize, query: this.query })
  }

  refresh() {
    this.customerService.loadCustomers(this.listUsersRequest)
  }

  onFilter(event) {
    this.customerService.loadCustomers({ currentPage: this.currentPage, pageSize: this.pageSize, query: event, modalFilters: this.modalFilters })
  }

  onModalFilters(event) {
    event.stopPropagation();
    const dialogRef = this.dialog.open(FilterDialogComponent, {
      width: "500px",
      data: this.modalFilters,
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data && !_.isEqual(this.modalFilters, data)) {
        this.modalFilters = data;

        this.onFilter(this.query)
      }
    });
  }

  async add() {
    await this.router.navigateByUrl('/admin/manage-customer')
  }

  async fetchCustomFields() {
    const res = await this.customFieldSettingsService.listCustomFieldsByEntity({ id: 'customers' })
    this.customFields = res.items
    return res.items
  }

  afterConfirmModal() {
    this.lsMessages = []
    this.isShowModal = false
    this.refresh()
  }

  async exportCustomers() {
    const res = await this.customerService.exportCustomers()
    saveAs(res.url, "price-targets.csv")
  }

  uploadCsvFileBtnClick() {
    //(document.querySelector("#importCsvFileInput") as HTMLElement).click();
  }

  async importCsvFile(event: HTMLInputElement) {

    this.lsMessages = [];
    const file = event[0];
    this.isShowModal = true;
    if (file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        this.csvContent = reader.result?.toString();
        Papa.parse(this.csvContent, {
          header: false,
          skipEmptyLines: true,
          complete: async (result) => {
            const rows = result.data;
            this.extractCsvHeader(rows[0]);
            if (this.lsMessages.length === 0) {
              for (let i = 1; i < rows.length; i++) {
                let skipItem: boolean = false
                const fields = rows[i];
                if (fields.length === this.csvHeader.length) {
                  const customer = new AddCustomerRequest()
                  customer.customerCode = fields[0];
                  customer.name = fields[1];
                  customer.type = fields[2];
                  customer.category = fields[3];
                  customer.chain = fields[4];
                  customer.territory = fields[5];
                  customer.address = fields[6];
                  customer.location = fields[7];
                  customer.externalId = fields[8];
                  customer.customFields = {};
                  const customFieldHeaders = this.csvHeader.slice(9);
                  const customFieldValues = fields.slice(9);
                  customFieldHeaders.forEach((header, index) => {
                    const value = customFieldValues[index];
                    customer.customFields[header] = value;
                  });


                  if (!customer.name) {
                    skipItem = true
                    this.lsMessages.push(this.languageService.translateSync('MissingNameAtLine') + ' ' + (i + 1))
                  }

                  if (!customer.category) {
                    skipItem = true
                    this.lsMessages.push(this.languageService.translateSync('MissingCategoryAtLine') + ' ' + (i + 1))
                  }

                  if (!customer.address) {
                    skipItem = true
                    this.lsMessages.push(this.languageService.translateSync('MissingAddressAtLine') + + ' ' + (i + 1))
                  }

                  if (!customer.customerCode) {
                    skipItem = true
                    this.lsMessages.push(this.languageService.translateSync('MissingCustomerCodeAtLine') + ' ' + (i + 1))
                  }
                  else {
                    if (this.listCustomerResponseForCsv.items.find(x => x.customerCode === customer.customerCode)) {
                      skipItem = true
                      this.lsMessages.push(this.languageService.translateSync('CustomerCodeAlreadyExistAtLine') + ' ' + (i + 1))
                    }
                  }
                  if (skipItem === false) {
                    const myPlaces = await this.googleMapsService.getPlaces(fields[6])
                    if (myPlaces.length > 0) {
                      const myGeoLocation = await this.googleMapsService.getGeoLocationOfPlace(myPlaces[0]);
                      if (myGeoLocation !== undefined) {
                        customer.address = myGeoLocation.foundAddress
                        customer.location = myGeoLocation.latLng
                      }
                    }
                    else {

                      if (fields[7] && fields[7].length > 0) {
                        if (fields[7].toString().indexOf(',') === -1) {
                          skipItem = true
                          this.lsMessages.push(this.languageService.translateSync('LatLongInvalidAtLine') + ' ' + (i + 1))
                        }
                        else {
                          const latLng = fields[7].split(',');
                          if (latLng.length != 2) {
                            skipItem = true
                            this.lsMessages.push(this.languageService.translateSync('LatLongInvalidAtLine') + ' ' + (i + 1))
                          }
                          else {

                            const myLocation = await this.googleMapsService.getGeoLocationLatLong(Number(latLng[0]), Number(latLng[1]))
                            if (myLocation !== undefined) {
                              customer.address = myLocation.foundAddress
                              customer.location = myLocation.latLng
                            }
                            else {
                              skipItem = true
                              this.lsMessages.push(this.languageService.translateSync('LatLongNotFoundAtLine') + ' ' + (i + 1))
                            }

                          }

                        }
                      }
                      else {

                        skipItem = true
                        this.lsMessages.push(this.languageService.translateSync('AddressNotFoundGoogleMaps') + ' ' + (i + 1))

                      }
                    }
                    if (skipItem === false) {
                      try {
                        this.lsMessages.push(this.languageService.translateSync(await this.save(customer)) + ' ' + (i + 2))
                        this.scrollToBottom()                        
                      } catch (error) {
                        this.lsMessages.push(error.toString() + ' ' + (i + 1))
                      }
                    }
                  }
                } else {
                  this.lsMessages.push(this.languageService.translateSync('MoreColumnsThanRecordsAtLine') + (i + 1))
                }

              }
            }
          },
          error: (error) => {
            console.error('CSV Parsing Error:', error);
          },
        });
      };
      reader.readAsText(file);
    }
  }

  /*
  
    async importCsvFile1(event: HTMLInputElement) { // THIS WHOLE METHOD SHOULD BE DELETED LOL ><
  
      // await this.customerService.importCustomers(url)// importPriceTargets
      // this.refresh()
  
      this.lsMessages = [];
      const file = event.files[0];
      this.isShowModal = true;
      if (file) {
        const reader = new FileReader();
        reader.onload = async (e) => {
          this.csvContent = reader.result?.toString();
  
          // Use papaparse to parse the CSV content
          Papa.parse(this.csvContent, {
            header: false, // Treat the first row as headers          
            skipEmptyLines: true,
            complete: async (result) => {
              const rows = result.data;
              this.extractCsvHeader(rows[0]); // Pass the header row
  
              if (this.lsMessages.length === 0) {
                for (let i = 1; i < rows.length; i++) {
                  let skipItem: boolean = false
                  const fields = rows[i];
                  if (fields === this.csvHeader) {
  
                    const customer = new AddCustomerRequest()
                    customer.externalId = fields[0]
                    customer.name = fields[1]
                    customer.category = fields[2]
                    customer.customerCode = fields[3]
                    customer.area = fields[4]
                    customer.chain = fields[5]
                    customer.territory = fields[6]
                    customer.address = fields[7]
                    customer.lastUpdate = fields[8]
  
                    if (!customer.name) {
                      skipItem = true
                      this.lsMessages.push(this.languageService.translateSync('MissingNameAtLine') + ' ' + (i + 2))
                    }
  
                    if (!customer.category) {
                      skipItem = true
                      this.lsMessages.push(this.languageService.translateSync('MissingCategoryAtLine') + ' ' + (i + 2))
                    }
  
                    if (!customer.address) {
                      skipItem = true
                      this.lsMessages.push(this.languageService.translateSync('MissingAddressAtLine') + + ' ' + (i + 2))
                    }
  
                    if (!customer.customerCode) {
                      skipItem = true
                      this.lsMessages.push(this.languageService.translateSync('MissingCustomerCodeAtLine') + ' ' + (i + 2))
                    }
                    else {
                      if (this.listCustomerResponseForCsv.items.find(x => x.customerCode === customer.customerCode)) {
                        skipItem = true
                        this.lsMessages.push(this.languageService.translateSync('CustomerCodeAlreadyExistAtLine') + ' ' + (i + 2))
                      }
                    }
  
                    if (skipItem === false) {
  
                      const tempAddress = customer.address
                      const myPlaces = await this.googleMapsService.getPlaces(fields[3])
                      if (myPlaces.length > 0) {
                        const myGeoLocation = await this.googleMapsService.getGeoLocationOfPlace(myPlaces[0]);
                        if (myGeoLocation !== undefined) {
                          customer.address = myGeoLocation.foundAddress
                          customer.location = myGeoLocation.latLng
                        }
                        this.save(customer)
                        this.lsMessages.push(this.languageService.translateSync('SuccessAtLine') + ' ' + (i + 2))
                        // this.lsMessages.push(this.languageService.translateSync('Address') + " " + tempAddress + " " + this.languageService.translateSync('FoundAs') + " " + customer.address + (i+2)) 
                      }
                      else {
                        skipItem = true
                        this.lsMessages.push(this.languageService.translateSync('AddressNotFoundGoogleMaps') + ' ' + (i + 2))
                      }
                    }
                  } else {
                    this.lsMessages.push(this.languageService.translateSync('MoreColumnsThanRecordsAtLine') + (i + 2))
                  }
  
                  // Your processing logic here, fields is an object with column names as keys
                }
              }
            },
            error: (error) => {
              // Handle parsing errors
              console.error('CSV Parsing Error:', error);
            },
          });
        };
        reader.readAsText(file);
      }
    }
  
  */

  extractCsvHeader(headerRow) {
    if (!headerRow) return []; // Return an empty array if headerRow is not available

    const requiredFields = [
      'externalId',
      'name',
      'category',
      'address',
      'location',
      'territory',
      'chain',
      'customerCode',
    ];

    this.csvHeader = headerRow.map(str => str);
    const missingFields = requiredFields.filter(field => {
      return !this.csvHeader.includes(field)
    });

    for (const item of missingFields) {
      this.lsMessages.push(this.languageService.translateSync(`MissingField${item}`));
    }
  }

  isFieldPresent(fieldName: string): boolean {
    return this.csvHeader.includes(fieldName);
  }

  async save(customer: AddCustomerRequest) {
    try {
     await this.customerService.addCustomer(customer)
     return 'SuccessAtLine'
    } catch (error) {
     return error.error.errors[0] + ''
    }
 }

}

function ngAfterViewChecked() {
 throw new Error('Function not implemented.');
}


function scrollToBottom() {
 throw new Error('Function not implemented.');
}