// tslint:disable: max-classes-per-file
// tslint:disable: no-namespace

import { Expose, Transform, Type } from 'class-transformer'
import { IsArray, IsBoolean, IsIn, IsNumber, IsOptional, IsString, ValidateNested } from 'class-validator'
import { CustomerWithId } from '../admin/customer'
import { UserRole } from '../admin/user'
import { Position, SortDirection, SortDirectionArray } from '../common'
import { Root } from './planogramStructure'
import { ProductWithCatalog } from './product'
export * as PlanogramStructure from './planogramStructure'

export class PlanogramsImageCatalogRequest {
    @Expose() @IsString() productId!: string
    @Expose() @IsString() catalogId!: string
}
export class UpdateImageCatalogFromPlanogramsRequest {
    @Expose() @Type(() => PlanogramWithId) @ValidateNested() @IsArray() planograms!: PlanogramWithId[]
    @Expose() @IsString() imageCatalogId!: string
    @Expose() @IsString() imgUrl!: string
}

export class SearchPlanogramsRequest {
    @Expose() @IsOptional() @IsString() mc1PlanogramId?: string
    @Expose() @IsNumber() currentPage!: number
    @Expose() @IsNumber() pageSize!: number
    @Expose() @IsString() query!: string
    @Expose() @IsOptional() @IsIn(['draft', 'published', 'deprecated', 'inactive']) filterByState?: 'draft' | 'published' | 'deprecated' | 'inactive'
    @Expose() @IsOptional() @IsIn(SortDirectionArray) sortDirection?: SortDirection
    @Expose() @IsOptional() @IsIn(['name', 'lastUpdate']) sortBy?: 'name' | 'lastUpdate'
}

export class SearchPlanogramsResponse {
    @Expose() @IsNumber() totalPlanograms!: number
    @Expose() @Type(() => PlanogramWithId) @ValidateNested() @IsArray() planograms!: PlanogramWithId[]
}

export class PlanogramsImageCatalogResponse {
    @Expose() @IsNumber() totalPlanograms!: number
    @Expose() @Type(() => PlanogramWithId) @ValidateNested() @IsArray() planograms!: PlanogramWithId[]
}
export class ListPlanogramsForAutocompleteRequest {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    @Expose() @IsOptional() @IsString() mc1PlanogramId?: string
    @Expose() @Transform(v => v.value.toLowerCase()) @IsString() query!: string
}

export class ListPlanogramsForAutocompleteResponse {
    @Expose() @Type(() => String) @IsArray() @IsString({ each: true }) arr!: string[]
}

export class DuplicatePlanogramRequest {
    @Expose() @IsString() originalPlanogramId!: string
    @Expose() @IsString() newPlanogramName!: string
}

export class PlanogramWithId extends Root {
    @Expose() @IsString() id!: string
}

export class PlanogramWithEditFlag extends PlanogramWithId {
    @Expose() @IsBoolean() isEditable!: boolean
}

export class AddPlanogramRequest extends Root { }

export class GetPlanogramResponse extends PlanogramWithId { }

export class UpdatePlanogramRequest {
    @Expose() @IsString() @IsOptional() planogramTmpImage?: string
    @Expose() @Type(() => PlanogramWithId) @ValidateNested() planogram!: PlanogramWithId
}

export class PublishPlanogramRequest {
    @Expose() @IsString() id!: string
    @Expose() @IsString() @IsOptional() mc1PlanogramId?: string
}

// Planongram associations
export class ListAssociatedUsersToPlanogramRequest {
    @Expose() @IsString() planogramId!: string
    @Expose() @IsNumber() currentPage!: number
    @Expose() @IsNumber() pageSize!: number
    @Expose() @IsString() @IsOptional() filter?: string
}

export class ListAssociatedCustomersToPlanogramRequest extends ListAssociatedUsersToPlanogramRequest { }

export namespace ListAssociatedUsersToPlanogram {

    export class Item {
        @Expose() @IsString() id!: string
        @Expose() @IsString() name!: string
        @Expose() @IsString() email!: string
        @Expose() @IsString() role!: UserRole
    }

    export class Response {
        @Expose() @IsNumber() totalItems!: number
        @Expose() @Type(() => Item) @ValidateNested() @IsArray() items!: Item[]
    }
}

export class ListAssociatedCustomresToPlanogramResponse {
    @Expose() @IsNumber() totalItems!: number
    @Expose() @Type(() => CustomerWithId) @ValidateNested() @IsArray() items!: CustomerWithId[]
}

export class AddUserPlanogramRequest {
    @Expose() @IsString() planogramId!: string
    @Expose() @IsString() userId!: string
}

export class AddCustomerPlanogramRequest {
    @Expose() @IsString() planogramId!: string
    @Expose() @IsString() customerId!: string
}

export class DeleteCustomerPlanogramRequest extends AddCustomerPlanogramRequest { }

export class DeleteUserPlanogramRequest extends AddUserPlanogramRequest { }

export class AddUserPlanogramResponse {
    @Expose() @Type(() => ListAssociatedUsersToPlanogram.Item) @ValidateNested() item!: ListAssociatedUsersToPlanogram.Item
}

export class AddCustomerPlanogramResponse {
    @Expose() @Type(() => CustomerWithId) @ValidateNested() item!: CustomerWithId
}

export class ListPlanogramsByProductIdRequest {
    @Expose() @IsString() productId!: string
    @Expose() @IsOptional() @IsString() mc1PlanogramId?: string | null | undefined
}

export class ListPlanogramsByProductIdResponse {
    @Expose() @IsNumber() totalPlanograms!: number
    @Expose() @Type(() => PlanogramWithEditFlag) @ValidateNested() @IsArray() planograms!: PlanogramWithEditFlag[]
}

export class SetPlanogramFromImageRequest {
    @Expose() @IsString() planogramId!: string
    @Expose() @IsString() fileUrl!: string
}

export class SetPlanogramFromImageProduct {
    @Expose() @IsString() label!: string
    @Expose() @Type(() => Position) position!: Position
    @Expose() @Type(() => ProductWithCatalog) @ValidateNested() product!: ProductWithCatalog
    @Expose() @IsNumber() detectionConfidence?: number
    @Expose() @IsNumber() classificationConfidence?: number
}

export class SetPlanogramFromImageShelves {
    @Expose() @Type(() => Position) position!: Position
    @Expose() @IsNumber() detectionConfidence?: number
}
export class SetPlanogramFromImageResponse {
    // @Expose() @IsString() planogramId!: string
    @Expose() @IsNumber() originalHeight!: number
    @Expose() @IsNumber() originalWidth!: number
    @Expose() @IsNumber() aspectRatio!: number
    @Expose() @Type(() => SetPlanogramFromImageProduct) @ValidateNested() @IsArray() products!: SetPlanogramFromImageProduct[]
    @Expose() @Type(() => SetPlanogramFromImageShelves) @ValidateNested() @IsArray() shelves!: SetPlanogramFromImageShelves[]
}

export class PlanogramParametersToggle {
    @Expose() @IsBoolean() classificationThresholdToggle: boolean;
    @Expose() @IsBoolean() autoCropToggle: boolean;
    @Expose() @IsBoolean() kpisFullToggle: boolean;
    @Expose() @IsBoolean() kpisSimpleToggle: boolean;
    @Expose() @IsBoolean() detectionZonesToggle: boolean;
    @Expose() @IsBoolean() annotationsToggle: boolean;
    @Expose() @IsBoolean() priceOcrToggle: boolean;
}

export class SetPlangoramAssociationsRequest {
    @Expose() @IsString() planogramId!: string
    @Expose() @IsArray() @IsString({ each: true }) userIds!: string[]
    @Expose() @IsArray() @IsString({ each: true }) customerIds!: string[]
    @Expose() @IsArray() @IsString({ each: true }) clusterIds!: string[]
}