<p-sidebar [(visible)]="isShowNav" (onHide)="isShowNavChange.emit(false)" [showCloseIcon]="false"
    [ngStyle]="{height: '100vh !important'}" [style]="{
        'width': '270px',
        'background-color': '#fff',
        'box-shadow': '0 0 10px 0 rgba(0, 0, 0, 0.2)',
        'border-radius': '0px',
        'padding': '0px'
    }">
    <div class="sidebar-box h-full">
        <div *ngIf="userInfo" class="sidebar-user-info-container">
            <img *ngIf="!!userInfo?.profileImgUrl && userInfo?.profileImgUrl !== 'default'"
                src="{{ userInfo?.profileImgUrl }}" alt="User profile image" style="width: 50px; height: 50px;">
            <img *ngIf="!userInfo?.profileImgUrl || userInfo?.profileImgUrl === 'default'"
                src="../../../assets/images/default_profile_picture.png" alt="Default profile image"
                style="width: 50px; height: 50px;">

            <div class="sidebar-user-info-content">
                <span class="sidebar-user-info-name">{{ userInfo?.name }}</span>
                <span class="sidebar-user-info-role">{{ userInfo?.role }}</span>
            </div>
        </div>

        <div class="navigation-menu">
            <mat-nav-list>
                <ng-container *ngFor="let item of menuItems">
                    <ng-container *ngIf="userInfo; else noPermissionCheck">
                        <ng-container *checkPermission="item.permission">
                            <ng-container *ngIf="item.children.length === 0">
                                <mat-list-item (click)="navigateToPage(item)">
                                    <a pRipple class="menu-item aa">
                                        <div class="menu-item-icon">
                                            <mat-icon>{{ item.iconName }}</mat-icon>
                                        </div>
                                        <div class="menu-item-title">
                                            <span class="font-medium"> {{ item.displayName | translate }}</span>
                                        </div>
                                    </a>
                                </mat-list-item>
                            </ng-container>
                            <ng-container *ngIf="item.children.length > 0">
                                <mat-accordion>
                                    <mat-expansion-panel>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                                <div class="menu-item">
                                                    <div class="menu-item-icon">
                                                        <mat-icon>{{ item.iconName }}</mat-icon>
                                                    </div>
                                                    <div class="menu-item-title">
                                                        <span class="font-medium"> {{ item.displayName | translate
                                                            }}</span>
                                                    </div>
                                                </div>
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <mat-list-item (click)="navigateToPage(child)"
                                            *ngFor="let child of item.children" class="font-medium">
                                            <a pRipple class="menu-item sub-menu aa">
                                                <div class="menu-item-icon">
                                                    <mat-icon>{{ child.iconName }}</mat-icon>
                                                </div>
                                                <div class="menu-item-title">
                                                    <span class="font-medium"> {{ child.displayName | translate
                                                        }}</span>
                                                </div>
                                            </a>
                                        </mat-list-item>
                                    </mat-expansion-panel>
                                </mat-accordion>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                    <ng-template #noPermissionCheck>
                        <mat-list-item (click)="navigateToPage(item)">
                            <a pRipple class="menu-item aa">
                                <div class="menu-item-icon">
                                    <mat-icon>{{ item.iconName }}</mat-icon>
                                </div>
                                <div class="menu-item-title">
                                    <span class="font-medium"> {{ item.displayName | translate }}</span>
                                </div>
                            </a>
                        </mat-list-item>
                    </ng-template>
                </ng-container>
            </mat-nav-list>
        </div>

        <div class="sidebar-footer">
            <span>v{{ version }}</span>
        </div>
    </div>
</p-sidebar>
